import React from "react";
import Hero from "../components/home/Hero";
import Items from "../components/home/Items";
import Facts from "../components/home/Facts";
import Works from "../components/home/Works";
import Newsletter from "../components/home/Newsletter";
import Footer from "../components/layout/Footer";
import { isLoggedIn } from "../utils/firebaseUtil";
import Dashboard from "./Dashboard";
import FAQ from "../components/home/FAQ";

export default function Home() {
  if (!isLoggedIn()) {
    return (
      <>
        <Hero />
        <Items />
        <Facts />
        <Works />
        <FAQ />
        <Newsletter />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Dashboard />
      </>
    );
  }
}
