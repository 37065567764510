import React from "react";
import play0 from "../images/play0.jpg";
import play1 from "../images/play1.jpg";
import play2 from "../images/play2.jpg";
import play3 from "../images/play3.jpg";
import play4 from "../images/play4.jpg";
import play5 from "../images/play5.jpg";
import play6 from "../images/play6.jpg";
import play7 from "../images/play7.jpg";
import play8 from "../images/play8.jpg";
import play9 from "../images/play9.jpg";
import play10 from "../images/play10.jpg";
import play11 from "../images/play11.jpg";
import play12 from "../images/play12.jpg";
import play13 from "../images/play13.jpg";
import play14 from "../images/play14.jpg";
import play15 from "../images/play15.jpg";
import play16 from "../images/play16.jpg";

export default function PlayCredentials() {
  return (
    <div className="px-10">
      <h1 className="mt-3 mb-5 text-center text-[26px] font-bold">
        How to create Play service credentials
        <br />
      </h1>
      <span>
        To reply to reviews from our platform you will need to create an app key
        in your Google account. Managing linked accounts requires the Owner
        role. If you are a viewer please share this article with a team member
        who has the required role.
        <br />
        <br />
      </span>
      <span className="fw-bold">
        1. Open the Setup dropdown and select API access in your Google Play
        Developer Console
        <br />
      </span>
      <img
        alt="service account 1"
        width="500rem"
        className="mt-3"
        src={play0}
      />
      <span className="fw-bold">
        <br /> <br /> 2. Choose a project to link your Google Play account to a
        Google Cloud Project
        <br />
      </span>
      <img
        alt="service account 2"
        width="600rem"
        className="mt-3"
        src={play1}
      />
      <span className="fw-bold">
        <br /> <br /> 3. Agree to the terms and conditions
        <br />
      </span>
      <img
        alt="service account 3"
        width="600rem"
        className="mt-3"
        src={play2}
      />
      <span className="fw-bold">
        <br /> <br /> 4. Select Create new service account
        <br />
      </span>
      <img
        alt="service account 4"
        width="600rem"
        className="mt-3"
        src={play3}
      />
      <span className="fw-bold">
        <br /> <br /> 5. Follow the link to the Google Cloud Console
        <br />
      </span>
      <img
        alt="service account 5"
        width="600rem"
        className="mt-3"
        src={play4}
      />
      <span className="fw-bold">
        <br /> <br /> 6. Create the service account
        <br />
      </span>
      <img
        alt="service account 6"
        width="600rem"
        className="mt-3"
        src={play5}
      />
      <span className="fw-bold">
        <br /> <br /> 7. Name the service account and continue
        <br />
      </span>
      <img
        alt="service account 7"
        width="600rem"
        className="mt-3"
        src={play6}
      />
      <span className="fw-bold">
        <br /> <br /> 8. You must select the Owner role to be able to reply to
        reviews
        <br />
      </span>
      <img
        alt="service account 8"
        width="600rem"
        className="mt-3"
        src={play7}
      />
      <span className="fw-bold">
        <br /> <br /> 9. In the Google Cloud Console, select Actions and Manage
        keys.
        <br />
      </span>
      <img
        alt="service account 9"
        width="900rem"
        className="mt-3"
        src={play8}
      />
      <span className="fw-bold">
        <br /> <br /> 10. Select Add key and Create new key.
        <br />
      </span>
      <img
        alt="service account 10"
        width="900rem"
        className="mt-3"
        src={play9}
      />
      <span className="fw-bold">
        <br /> <br /> 11. Make sure JSON is selected and select Create to create
        and download the JSON key.
        <br />
      </span>
      <img
        alt="service account 11"
        width="600rem"
        className="mt-3"
        src={play10}
      />
      <span className="fw-bold">
        <br /> <br /> 12. Back in the Google Play Console, select Grant Access
        on the newly created account
        <br />
      </span>
      <img
        alt="service account 12"
        width="900rem"
        className="mt-3"
        src={play11}
      />
      <span className="fw-bold">
        <br /> <br /> 13. Grant the permissions to View app information and
        Reply to reviews
        <br />
      </span>
      <img
        alt="service account 13"
        width="900rem"
        className="mt-3"
        src={play12}
      />
      <span className="fw-bold">
        <br /> <br /> 14. Send the invite
        <br />
      </span>
      <img
        alt="service account 14"
        width="500rem"
        className="mt-3"
        src={play13}
      />
      <span className="fw-bold">
        <br /> <br /> 15. In App Permissions section add your app and apply
        <br />
      </span>
      <img
        alt="service account 15"
        width="600rem"
        className="mt-3"
        src={play14}
      />
      <span className="fw-bold">
        <br /> <br /> 16. Apply chosen permissions
        <br />
      </span>
      <img
        alt="service account 16"
        width="900rem"
        className="mt-3"
        src={play15}
      />
      <span className="fw-bold">
        <br /> <br /> 17. Open JSON file that you downloaded, copy the content
        to the Service Account JSON field and save
        <br />
      </span>
      <img
        alt="service account 17"
        width="500rem"
        className="mt-3"
        src={play16}
      />
      <span className="fw-bold mb-5">
        <br /> <br /> You are done! Congratulations! Now you can go to your app.{" "}
        <br />
      </span>
    </div>
  );
}
