import { useEffect, useState } from "react";

export default function TextAreaDelayed({
  value = "",
  onChange = () => {},
  onUserStoppedTyping = () => {},
  placeholder = "",
  className = "",
  disabled = false,
  rows = 1,
}) {
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    setUpdateCount(updateCount + 1);
    const delayDebounceFn = setTimeout(async () => {
      if (updateCount < 1 || disabled) return;
      onUserStoppedTyping();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <textarea
      autoComplete="off"
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      rows={rows}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onBlur={onUserStoppedTyping}
    />
  );
}
