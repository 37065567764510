import { auth } from "./firebaseUtil";
import axios from "axios";
import { playConsoleApi } from "../config";

async function getRequest(url, resultName, useAuth = true) {
  let getRequestInit = {};
  if (useAuth === true) {
    getRequestInit = {
      headers: new Headers({
        Authorization: await auth.currentUser.getIdToken(),
      }),
    };
  }
  const response = await fetch(url, getRequestInit);
  let data = await response.json();
  if (!response.ok) {
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}

async function postRequest(url, body) {
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  const requestOptions = {
    method: "POST",
    url: url,
    data: formData,
    headers: { Authorization: await auth.currentUser.getIdToken() },
  };
  try {
    let response = await axios(requestOptions);
    return response["data"];
  } catch (error) {
    return { success: false, message: error.response.message };
  }
}

async function postRequestJson(url, body, useAuth = true) {
  let headersObj = {
    "Content-Type": "application/json",
  };
  if (useAuth === true) {
    headersObj["Authorization"] = await auth.currentUser.getIdToken();
  }
  const requestOptions = {
    method: "POST",
    headers: headersObj,
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(url, requestOptions);
    return await response.json();
  } catch (error) {
    return { success: false, message: error.response.message };
  }
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data) {
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  }
  return ret.join("&");
}

export async function addNewsletterEmail(email) {
  return await postRequestJson(
    "/api/add-newsletter-email",
    { email: email },
    false
  );
}

export async function syncUser() {
  return await postRequestJson(`/api/sync-user`, {});
}

export async function createCustomToken() {
  return await postRequestJson(`/api/create-custom-token`, {});
}

export async function subscribeUser(type) {
  return await postRequestJson(`/api/subscribe`, { type: type });
}

export async function getActiveSubscription() {
  return await getRequest(`/api/get-active-subscription`);
}

export async function getApp(appId) {
  return await getRequest(`/api/get-app?app_id=${appId}`);
}

export async function listApps() {
  return await getRequest(`/api/list-apps`);
}

export async function addApp(appName, supportEmail, packageName) {
  return await postRequestJson(`/api/add-app`, {
    platform: playConsoleApi,
    app_name: appName,
    support_email: supportEmail,
    package_name: packageName,
  });
}

export async function deleteApp(appId) {
  return await postRequestJson(`/api/delete-app?app_id=${appId}`, {});
}

export async function addServicedAccount(appId, serviceAccountJson) {
  return await postRequestJson(`/api/add-serviced-account?app_id=${appId}`, {
    service_account_json: serviceAccountJson,
  });
}

export async function uploadReviewFile(appId, file) {
  return await postRequest(`/api/upload-review-file?app_id=${appId}`, {
    file: file,
  });
}

export async function listConversations(appId, filterName, page) {
  let queryDict = {
    app_id: appId,
    page: page,
  };
  if (filterName !== null) {
    queryDict["filter"] = filterName;
  }
  return await getRequest(`/api/list-conversations?${encodeQueryData(queryDict)}`);
}

export async function listAllReviews(unanswered) {
  let queryDict = {};
  if (unanswered) queryDict["unanswered"] = true;
  return await getRequest(
    `/api/list-all-reviews?${encodeQueryData(queryDict)}`
  );
}

export async function generateReply(appId, conversationId) {
  return await postRequestJson(`/api/generate-reply?app_id=${appId}`, {
    conversation_id: conversationId,
  });
}

export async function replyToConversation(conversationId, replyText) {
  return await postRequestJson("/api/reply-to-conversation", {
    conversation_id: conversationId,
    reply_text: replyText,
  });
}

export async function listMemories(appId) {
  return await getRequest(`/api/list-memories?app_id=${appId}`);
}

export async function deleteMemory(appId, memoryId) {
  return await postRequestJson(`/api/delete-memory?app_id=${appId}`, {
    memory_id: memoryId,
  });
}

export async function addMemory(appId, newMemory) {
  return await postRequestJson(`/api/add-memory?app_id=${appId}`, {
    memory: newMemory,
  });
}

export async function updateMemory(appId, memory) {
  return await postRequestJson(`/api/update-memory?app_id=${appId}`, {
    memory: memory,
  });
}

export async function addReviewNotes(reviewId, notes) {
  return await postRequestJson("/api/add-reply-notes", {
    review_id: reviewId,
    notes: notes,
  });
}

export async function getTemplateMessages(appId) {
  let queryDict = { app_id: appId };
  return await getRequest(
    `/api/list-template-messages?${encodeQueryData(queryDict)}`
  );
}

export async function insertTemplateMessage(appId, template) {
  let queryDict = { app_id: appId };
  return await postRequestJson(
    `/api/insert-template-message?${encodeQueryData(queryDict)}`,
    { template: template }
  );
}

export async function updateTemplateMessage(appId, template) {
  let queryDict = { app_id: appId };
  return await postRequestJson(
    `/api/update-template-message?${encodeQueryData(queryDict)}`,
    { template: template }
  );
}

export async function deleteTemplateMessage(appId, templateId) {
  let queryDict = { app_id: appId };
  return await postRequestJson(
    `/api/delete-template-message?${encodeQueryData(queryDict)}`,
    { template_id: templateId }
  );
}

export async function listFineTuning() {
  return await getRequest(`/api/list-fine-tuning`);
}

export async function createFineTuningModel(modelName) {
  return await postRequestJson(`/api/create-fine-tuning-model`, {
    model_name: modelName,
  });
}

export async function deleteFineTuningModel(modelId) {
  return await postRequestJson(`/api/delete-fine-tuning-model`, {
    model_id: modelId,
  });
}

export async function startFineTuning(modelId) {
  return await postRequestJson(`/api/start-fine-tuning`, {
    model_id: modelId,
  });
}

export async function getFineTuningProcess(modelId) {
  return await getRequest(`/api/get-fine-tuning-process?model_id=${modelId}`);
}

export async function getTrainingData(fineTuningId) {
  let queryDict = { fine_tuning_id: fineTuningId };
  return await getRequest(
    `/api/get-training-data?${encodeQueryData(queryDict)}`
  );
}

export async function insertTrainingData(fineTuningId, before, after) {
  return await postRequestJson(`/api/insert-training-data`, {
    fine_tuning_id: fineTuningId,
    training_data: {
      before: before,
      after: after,
    },
  });
}

export async function updateTrainingData(trainingData) {
  return await postRequestJson(`/api/update-training-data`, {
    training_data: trainingData,
  });
}

export async function deleteTrainingData(trainingDataId) {
  return await postRequestJson(`/api/delete-training-data`, {
    training_data_id: trainingDataId,
  });
}

export async function listOpenAiFiles() {
  return await getRequest(`/api/list-open-ai-files`);
}

export async function getOpenAiFile(fileId) {
  return await getRequest(`/api/get-open-ai-file?file_id=${fileId}`);
}

export async function deleteOpenAiFile(fileId) {
  return await postRequestJson(`/api/delete-open-ai-file`, {
    file_id: fileId,
  });
}

export async function listOpenAiFineTunedModels() {
  return await getRequest(`/api/list-open-ai-fine-tuned-models`);
}

export async function deleteOpenAiFineTunedModel(modelId) {
  return await postRequestJson(`/api/delete-open-ai-fine-tuned-model`, {
    model_id: modelId,
  });
}
