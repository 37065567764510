import SupportedPlatforms from "./SupportedPlatforms";
import { isPaidUser } from "../../utils/firebaseUtil";
import AddAppButtons from "./AddAppButtons";
import React from "react";
import { useSelector } from "react-redux";
import MediaUrl from "../common/MediaUrl";

export default function Welcome() {
  const subscriptionType = useSelector((state) => state.subscription.type);

  return (
    <div>
      <center>
        <h2 className="font-lat my-10 text-[1.5rem]">Welcome to ReplyBuddy!</h2>
        <p className="my-10 font-lato">Download our Chrome Extension at:</p>
        <MediaUrl
          url="https://chrome.google.com/webstore/detail/replybuddy/nmmnbgbonfhaoajlpppcekmbodbonjap"
          text="ReplyBuddy"
          icon={require("../../images/logo.png")}
        />
        <p className="my-10 font-lato">Start using it at:</p>
        <SupportedPlatforms />
      </center>
      {isPaidUser(subscriptionType) && (
        <center>
          <p className="my-10 font-lato">or setup your app</p>
          <AddAppButtons />
        </center>
      )}
    </div>
  );
}
