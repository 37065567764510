import React from "react";

export default function MessageSentiment({ sentiment }) {
  function getSentimentEmoji(sentiment) {
    if (!sentiment) return "";
    if (sentiment === "positive") {
      return "😁";
    } else if (sentiment === "negative") {
      return "😡";
    } else {
      return "❓";
    }
  }

  if (sentiment) {
    return (
      <span className={`mx-1 px-2 py-1`}>{getSentimentEmoji(sentiment)}</span>
    );
  }
}
