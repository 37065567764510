import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function SimpleDropdown({ label, menuItems, buttonClasses }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={"main-button bg-primary text-white " + buttonClasses}
      >
        {label}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="my-auto -mr-1 ml-2 w-3"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {menuItems.map((item, index) => (
              <Menu.Item key={index}>
                <span
                  className="text-sm block cursor-pointer px-4 py-2 text-gray-700"
                  onClick={() => item.handleClick()}
                >
                  {item.label}
                </span>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
