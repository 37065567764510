import {
  deleteFineTuningModel,
  startFineTuning,
  getFineTuningProcess,
} from "../../utils/apiRequests";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FineTuningElement({ modelId, modelName, fetchModels }) {
  const navigate = useNavigate();
  const [fineTuningProcess, setFineTuningProcess] = useState(null);
  let interval = 0;

  useEffect(() => {
    fetchFineTuningEvents();
    return () => clearInterval(interval);
  }, []);

  function fetchFineTuningEvents() {
    clearInterval(interval);
    interval = setInterval(() => {
      getFineTuningProcess(modelId).then((ftProcess) => {
        setFineTuningProcess(ftProcess);
        if (ftProcess["status"] === "succeeded") {
          clearInterval(interval);
        }
      });
    }, 20000);
  }

  return (
    <div key={modelId} className="my-4">
      <div className="my-1 flex-row">
        <span
          className="text-xl inline-block flex-grow cursor-pointer rounded-lg border bg-white p-5 font-bold"
          onClick={() => navigate(`/fine-tuning/${modelId}/training-data`)}
        >
          {modelName}
        </span>
        <button
          className="main-button my-auto mx-4 inline-block h-auto rounded border border-blue-300 p-2 text-blue-600"
          onClick={async () => {
            startFineTuning(modelId).then(() => fetchFineTuningEvents());
          }}
        >
          Start Fine Tuning
        </button>

        <button
          id="input-group-dropdown-1"
          className="my-auto mx-4 inline-block h-auto rounded border border-red-300 p-2 text-red-600"
          value=""
          onClick={async () => {
            await deleteFineTuningModel(modelId);
            await fetchModels();
          }}
        >
          Delete
        </button>
      </div>
      <div className="my-1 flex-row">
        <table>
          <tbody>
            {fineTuningProcess &&
              fineTuningProcess["events"].map((eventObj, index) => (
                <tr key={index}>
                  <td>
                    {new Date(
                      eventObj["created_at"] * 1000
                    ).toLocaleTimeString()}
                    :{"   "}
                  </td>
                  <td>{eventObj["message"]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
