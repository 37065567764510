import React from "react";
import NewsletterEmail from "./NewsletterEmail";

export default function Hero() {
  return (
    <div className="container mx-auto mt-12 max-w-[82rem]">
      <div className="mx-auto  text-center">
        <span className="mx-auto rounded bg-neutral50 py-1.5 px-3 font-lato text-[14px] font-normal text-neutral">
          Hey, buddy!
        </span>
      </div>

      <div className="heading mt-3 px-4 text-center md:px-32 laptop:px-[15.5rem]">
        <h1 className="mt-[18px] hidden font-lato  text-[32px] font-bold leading-[125%] text-primary sm:text-4xl desktop:block">
          AI writing assistant <span className="text-black">to respond </span>
          <br></br>
          customer reviews
        </h1>
        <h1 className="mt-[18px] font-lato text-[32px]  font-bold leading-[125%] text-primary sm:text-4xl desktop:hidden">
          Reply to{" "}
          <span className="text-black">
            your customer <br></br>
          </span>
          reviews with AI
        </h1>

        <p className="mt-4 px-0 text-center font-lato text-[14px] font-normal leading-7 text-neutral sm:text-[18px]">
          For today's customers, trust is king. That's why we go
          <br className="hidden tablet:block" /> further to help you genuinely
          reply to reviews
        </p>
      </div>
      <div className="flex justify-center pt-8">
        <NewsletterEmail />
      </div>
      {/*<div className="mt-[1.95rem] mx-auto text-center rounded-md">*/}
      {/*  <a*/}
      {/*    href="static/webapp/src/components/Hero/Hero#"*/}
      {/*    className="text-xs leading-none rounded text-white bg-primary font-lato font-normal flex w-[10.75rem] h-[2.06rem] items-center justify-center mx-auto"*/}
      {/*  >*/}
      {/*    <span className='text-[0.875rem] leading-none'>Get Chrome Extension</span>*/}
      {/*  </a>*/}
      {/*</div>*/}

      <div className="background-hero mx-auto mt-[-3.5rem] flex h-[19.8rem] items-center justify-center bg-[url('images/Decor-group.png')] bg-cover bg-center bg-no-repeat text-center sm:-mt-8 md:-mt-20 md:h-[37.3rem] medium:h-[37.8rem]">
        <div className="w-[80%] max-w-xl pt-[4rem] md:max-w-3xl md:pt-[9.5rem]">
          <img src={require("../../images/example.png")} alt="example" />
        </div>
      </div>
    </div>
  );
}
