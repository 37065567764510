import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import { auth } from "./utils/firebaseUtil";
import { useAuthState } from "react-firebase-hooks/auth";
import Messages from "./pages/Messages";
import AddApp from "./pages/AddApp";
import AddTestApp from "./pages/AddTestApp";
import AppSettings from "./pages/AppSettings";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import PlayCredentials from "./pages/PlayCredentials";
import Pricing from "./pages/Pricing";
import MyProfile from "./pages/MyProfile";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./pages/Dashboard";
import FineTuning from "./pages/FineTuning";
import TrainingData from "./pages/TrainingData";

export default function App() {
  const [user, loading] = useAuthState(auth);
  useEffect(() => {}, [user, loading]);

  function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    if (user === null) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
  }

  return loading ? (
    <div />
  ) : (
    <div>
      <Navbar />
      <div className="m-0">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/apps" element={<Dashboard />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/my-profile"
            element={
              <RequireAuth>
                <MyProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/apps/add"
            element={
              <RequireAuth>
                <AddApp />
              </RequireAuth>
            }
          />
          <Route
            path="/create-play-credentials"
            element={
              <RequireAuth>
                <PlayCredentials />
              </RequireAuth>
            }
          />
          <Route
            path="/apps/add-test"
            element={
              <RequireAuth>
                <AddTestApp />
              </RequireAuth>
            }
          />
          <Route
            path="/apps/:appId/messages"
            element={
              <RequireAuth>
                <Messages />
              </RequireAuth>
            }
          />
          <Route
            path="/apps/:appId/app-settings"
            element={
              <RequireAuth>
                <AppSettings />
              </RequireAuth>
            }
          />
          <Route
            path="/fine-tuning"
            element={
              <RequireAuth>
                <FineTuning />
              </RequireAuth>
            }
          />
          <Route
            path="/fine-tuning/:fineTuningId/training-data"
            element={
              <RequireAuth>
                <TrainingData />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
