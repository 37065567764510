import React, { useState } from "react";
import SimpleSwitch from "../components/common/SimpleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCrown } from "@fortawesome/free-solid-svg-icons";
import { subscribeUser } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";

export default function Pricing() {
  const navigate = useNavigate();
  const [isYearly, setIsYearly] = useState(false);

  async function onSubscribe(type) {
    await subscribeUser(type);
    navigate("/");
  }

  let plans = [
    {
      name: "Free",
      discretion:
        "Save your templates and use them for replying whenever you want",
      price: {
        monthly: 0,
        annually: 0,
      },
      features: [
        { name: "Chrome extension", isPro: false },
        { name: "Unlimited reply templates", isPro: false },
      ],
      onClick: () => onSubscribe("trial"),
      buttonLabel: "Try for free",
    },
    {
      name: "Pro",
      discretion:
        "Start using tremendous opportunities of AI text generation to reply your reviews",
      price: {
        monthly: 59,
        annually: 59 * 12 - 100,
      },
      features: [
        { name: "Chrome extension", isPro: false },
        { name: "Unlimited reply templates", isPro: false },
        { name: "AI Generated replies", isPro: true },
        { name: "Sentiment analysis", isPro: true },
        { name: "Categories analysis", isPro: true },
      ],
      onClick: () => onSubscribe("pro"),
      buttonLabel: "Start Pro",
    },
  ];

  return (
    <div className="h-screen bg-primary25">
      <div className="flex items-center justify-center space-x-4 pt-10">
        <span className="text-base font-medium">Bill Monthly</span>
        <SimpleSwitch onChange={(val) => setIsYearly(val)} />
        <span className="text-base font-medium">Bill Annually</span>
      </div>
      <div className="mt-16 flex flex-col items-center justify-center space-y-8 lg:flex-row lg:items-stretch lg:space-x-8 lg:space-y-0">
        {plans.map((plan, index) => (
          <section
            key={index}
            className="flex w-full max-w-sm flex-col space-y-6 rounded-lg bg-white p-12 shadow-md"
          >
            <div className="flex-shrink-0">
              <span
                className={
                  "text-4xl font-medium tracking-tight " +
                  (plan.name === "Basic" ? "text-green-500" : "")
                }
              >
                {isYearly ? plan.price.annually : plan.price.monthly}€
              </span>
              <span className="text-gray-400">
                {isYearly ? "/year" : "/month"}
              </span>
            </div>

            <div className="flex-shrink-0 space-y-2 border-b pb-6">
              <h2 className="text-2xl font-normal">{plan.name}</h2>
              <p className="text-sm text-gray-400">{plan.discretion}</p>
            </div>

            <ul className="flex-1 space-y-4">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  {feature.isPro ? (
                    <FontAwesomeIcon
                      icon={faCrown}
                      color="orange"
                      className="my-auto h-5"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="#86eeac"
                      className="my-auto h-5"
                    />
                  )}
                  <span className="text-base ml-3 font-medium">
                    {feature.name}
                  </span>
                </li>
              ))}
            </ul>

            <div className="flex-shrink-0 pt-4">
              <button
                className={
                  "inline-flex w-full max-w-xs items-center justify-center rounded-full border px-4 py-2 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 " +
                  (plan.name === "Basic"
                    ? "bg-primary text-white hover:bg-primaryVariant"
                    : "hover:bg-primaryVariant hover:text-white")
                }
                onClick={plan.onClick}
              >
                {plan.buttonLabel}
              </button>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}
