export function toDateTimeString(secs) {
  let t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t.toLocaleString();
}

export function currentDateTimeString() {
  return new Date().toLocaleString();
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
