import React from "react";

export default function Facts() {
  return (
    <div className="mt-8 bg-primary25 py-10 query:py-20">
      <div className="container mx-auto mt-0 max-w-7xl px-4 md:px-0">
        <div className="mx-auto  text-center">
          <span className="mx-auto rounded bg-white py-1.5 px-3 font-lato text-[14px] font-normal text-neutral">
            Some facts
          </span>
        </div>

        <h1 className="pt-4 text-center font-latosemibold  text-[32px] leading-[125%] text-neutral900 sm:text-[2.25rem]">
          Why replying to reviews is important
        </h1>

        {/* statistics */}
        <div className="statistics mx-auto mt-10 flex max-w-screen-spacer items-center justify-between rounded rounded-t-2xl bg-white py-[18px] px-[24px]">
          <div className="content flex w-full flex-col justify-between query:flex-row">
            <div className="flex w-full flex-col border-b-[1px] border-solid border-neutral200 pt-0 pb-7 query:w-[272px] query:border-b-[0px] query:border-r-[1px] query:border-dotted query:border-neutral200 query:px-0 query:pt-0 query:pb-0 query:pr-8">
              <div className="main flex items-center">
                <h1 className="text-center font-lato text-[36px] font-bold  text-primary">
                  70%
                </h1>
                <p className="text-base pl-[18px] font-lato font-normal leading-6 text-neutral">
                  Reviews updated if replied to
                </p>
              </div>
              <h3 className="pt-3 text-right font-lato text-[14px] font-medium leading-[17.5px] text-primaryVariant">
                — Google I/O 2019
              </h3>
            </div>

            {/* 2nd-column */}

            <div
              className="flex w-full flex-col border-b-[1px]
              border-solid border-neutral200 pt-8 pb-6 query:ml-[1.9rem] query:w-[247px] query:border-b-[0px] query:border-r-[1px] query:border-dotted query:border-neutral200 query:px-0 query:pt-0 query:pb-0 query:pr-[1.8rem]"
            >
              <div className="main flex items-center">
                <h1 className="text-center font-lato text-[2.25rem] font-bold text-primary query:pl-[0.2rem]">
                  53%
                </h1>
                <p className="text-base pl-[18px] font-lato font-normal leading-6 text-neutral">
                  Expect replies to reviews
                </p>
              </div>
              <h3 className="pt-3 text-right font-lato text-[14px] font-medium leading-[17.5px] text-primaryVariant query:pr-0">
                — ReviewTrackers Review 2022
              </h3>
            </div>

            {/* 3rd column  */}
            <div className="flex w-full flex-col pt-8 pb-0 query:ml-[1.9rem] query:w-[277px] query:px-0 query:pt-0 query:pb-0">
              <div className="main flex items-center query:justify-end">
                <h1 className="text-center font-lato text-[2.25rem] font-bold text-primary">
                  33%
                </h1>
                <p className="text-base pl-[18px] font-lato font-normal leading-6 text-neutral">
                  Higher purchase rate if painpoints addressed
                </p>
              </div>
              <h3 className="pt-3 text-right font-lato text-[14px] font-medium leading-[17.5px] text-primaryVariant">
                — Harvard business review 2021
              </h3>
            </div>
          </div>
        </div>
      </div>

      <img
        src={require("../../images/Arrow.png")}
        alt="item2"
        className="mx-auto mb-4 mt-6"
      />

      <div className="container mx-auto max-w-7xl px-4 md:px-4">
        <h1 className="px-4 text-center font-latosemibold  text-[32px] text-black sm:text-[2.25rem]">
          We simplify reviews replying process
        </h1>

        <p className="mt-5 px-4 text-center font-lato text-[16px] font-normal  text-neutral">
          Use the power of the most advanced Artificial Intelligence language
          model to optimise your workflow
        </p>

        <div className="mx-auto -ml-6 mt-12 flex w-[114%] flex-row justify-between custom:ml-auto custom:max-w-[400px] tablet:max-w-[468px] ">
          <div className="box flex w-[128px] flex-col items-center text-center">
            <img
              src={require("../../images/Icon-1.png")}
              alt="item2"
              className="mx-5 w-20 tablet:w-[88px]"
            />
            <h3 className="mt-6 px-5 text-center font-lato text-[14px] text-neutral tablet:text-[16px]">
              Reduced Time & Cost
            </h3>
          </div>

          <div className="box flex w-[160px] flex-col items-center text-center">
            <img
              src={require("../../images/icon-2.png")}
              alt="item2"
              className="mx-8 w-20 tablet:w-[88px]"
            />
            <h3 className="mt-6 px-5 text-center font-lato text-[14px] text-neutral tablet:text-[16px]">
              Reviews Insights In Real Time
            </h3>
          </div>

          <div className="box flex w-[128px] flex-col items-center text-center ">
            <img
              src={require("../../images/icon-3.png")}
              alt="item2"
              className="mx-5 w-20 tablet:w-[88px]"
            />
            <h3 className="mt-6 px-5 text-center font-lato text-[14px] text-neutral  tablet:text-[16px]">
              Personalized Responses
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
