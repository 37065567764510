import React from "react";
import "../../index.css";

export default function Items() {
  return (
    <div className="container mx-auto mt-12 max-w-7xl">
      <div className="flex flex-col items-center justify-center py-4 md:flex-row">
        <div className="available mb-6 flex flex-col items-center justify-center md:mb-0">
          <div className="images flex flex-row">
            <img
              src={require("../../images/item-1.png")}
              alt="item1"
              className="mr-4 h-14 w-14 md:h-20 md:w-20"
            />
            <img
              src={require("../../images/item-2.png")}
              alt="item2"
              className="h-14 w-14 md:h-20 md:w-20"
            />
          </div>
          <div className="text">
            <h5 className="text-base mt-3 font-lato font-normal text-neutral">
              Available
            </h5>
          </div>
        </div>

        {/* comin soon  */}
        <div className="available ml-0 flex flex-col items-center justify-center md:ml-16">
          <div className="images flex flex-row">
            <img
              src={require("../../images/item-3.png")}
              alt="item1"
              className="mr-4 h-14 w-14 md:h-20 md:w-20"
            />
            <img
              src={require("../../images/item-4.png")}
              alt="item2"
              className="mr-4 h-14 w-14 md:h-20 md:w-20"
            />
            <img
              src={require("../../images/item-5.png")}
              alt="item2"
              className="mr-4 h-14 w-14 md:h-20 md:w-20"
            />
            <img
              src={require("../../images/item-6.png")}
              alt="item2"
              className="h-14 w-14 md:h-20 md:w-20"
            />
          </div>
          <div className="text">
            <h5 className="text-base mt-3 font-lato font-normal text-neutral">
              Comming Soon
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
