import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function CustomPagination({ page, pageCount, setPage }) {
  let padding = 3;
  let pagesList = [...Array(pageCount).keys()].slice(
    Math.max(1, page - padding),
    Math.min(pageCount, page + padding + 1)
  );
  let isEllipsisBefore = page - padding > 1;
  let isEllipsisAfter = page + padding < pageCount;

  function PaginationElement({ pageIndex, label, onClick }) {
    return (
      <div
        onClick={onClick}
        className={
          "text-sm relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 font-medium hover:bg-gray-50 " +
          (pageIndex === page ? "bg-gray-200" : "")
        }
        role="button"
        aria-current="page"
      >
        {label}
      </div>
    );
  }

  return (
    <div className="flex flex-1 justify-center">
      <nav
        className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <div
          onClick={() => setPage(page - 1)}
          className="inline-flex items-center rounded-l-md border border-gray-300 p-2 text-gray-500 hover:bg-gray-50"
          role="button"
        >
          <span className="sr-only">Previous</span>
          <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
        </div>
        {isEllipsisBefore && (
          <PaginationElement
            pageIndex={1}
            label="..."
            onClick={() => setPage(page - padding - 1)}
          />
        )}
        {pagesList.map((pageIndex) => (
          <PaginationElement
            key={pageIndex}
            pageIndex={pageIndex}
            label={pageIndex}
            onClick={() => setPage(pageIndex)}
          />
        ))}
        {isEllipsisAfter && (
          <PaginationElement
            pageIndex={pageCount}
            label="..."
            onClick={() => setPage(page + padding + 1)}
          />
        )}
        <div
          onClick={() => setPage(pageCount)}
          className="text-sm relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 font-medium text-gray-500 hover:bg-gray-50"
          role="button"
        >
          <span className="sr-only">Next</span>
          <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
        </div>
      </nav>
    </div>
  );
};
