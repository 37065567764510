import React from "react";

export default function TermsOfUse() {
  return (
    <div>
      <span className="fs-1-8">
        Terms of Use
        <br />
      </span>
      <span>
        Last updated June 27, 2022
        <br />
      </span>
    </div>
  );
}
