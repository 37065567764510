import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TextAreaDelayed from "../common/TextAreaDelayed";

export default function TemplateMessageElement({
  template,
  onTemplateChanged,
  onUserStoppedTyping,
  onDeleteTemplate,
}) {
  const onTemplateVariationChange = (newValue, index) => {
    const newTemplateMessage = [...template["template_variations"]];
    newTemplateMessage[index] = newValue;
    onTemplateChanged("template_variations", newTemplateMessage);
  };

  return (
    <div className="flex">
      <div className="m-5 flex-grow">
        <div className="mb-2">
          <TextAreaDelayed
            className="w-[50%] rounded border px-2 py-1"
            placeholder="Message Title"
            value={template["name"] || ""}
            onChange={(newValue) => onTemplateChanged("name", newValue)}
            onUserStoppedTyping={onUserStoppedTyping}
          />
        </div>
        <div>
          {template["template_variations"].map(function (
            template,
            index
          ) {
            return (
              <TextAreaDelayed
                key={index}
                className="w-full rounded border px-2 py-1"
                rows={2}
                placeholder="We really appreciate your feedback..."
                value={template}
                onChange={(newValue) =>
                  onTemplateVariationChange(newValue, index)
                }
                onUserStoppedTyping={onUserStoppedTyping}
              />
            );
          })}
        </div>
      </div>
      <div className="my-auto mr-5 inline-block">
        <FontAwesomeIcon
          icon={faTrash}
          className="main-button rounded-xl border p-2 text-red-600"
          onClick={onDeleteTemplate}
        />
      </div>
    </div>
  );
}
