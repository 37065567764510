import React from "react";
import TextAreaDelayed from "../common/TextAreaDelayed";

export default function TrainingDataElement({
  before,
  onBeforeChange,
  after,
  onAfterChange,
  onUserStoppedTyping,
}) {
  return (
    <>
      <TextAreaDelayed
        className="w-full rounded border px-2 py-1"
        placeholder="Prompt"
        rows={2}
        value={before}
        onChange={(value) => onBeforeChange(value)}
        onUserStoppedTyping={onUserStoppedTyping}
      />
      <TextAreaDelayed
        className="w-full rounded border px-2 py-1"
        placeholder="Completion"
        rows={1}
        value={after}
        onChange={(value) => onAfterChange(value)}
        onUserStoppedTyping={onUserStoppedTyping}
      />
    </>
  );
}
