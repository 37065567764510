import React from "react";

export default function ReviewText({
  languageCode,
  modifiedOn,
  text,
  textTranslated,
}) {
  return (
    <>
      <div className="rounded-lg border border-gray-200 bg-gray-100 p-2">
        {textTranslated}
      </div>
      {languageCode !== "en" && (
        <div className="mt-1 bg-gray-200">
          {languageCode}: {text}
        </div>
      )}
      <p className="grid place-content-end text-[12px] italic">
        {new Date(modifiedOn).toLocaleString()}
      </p>
    </>
  );
}
