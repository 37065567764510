import React, { useState } from "react";
import { addNewsletterEmail } from "../../utils/apiRequests";

export default function NewsletterEmail() {
  const [email, setEmail] = useState("");

  const collectEmailForNewsletter = () => {
    addNewsletterEmail(email).then(() =>
      alert("Thank you for your interest! We'll reach out to you shortly.")
    );
  };

  return (
    <div className="relative inline-block">
      <input
        onChange={(e) => setEmail(e.target.value)}
        className="mx-auto block h-[3rem] w-[18rem] rounded border border-gray-300 pl-[10px] lg:w-[22.5rem] lg:pr-[7rem]"
        placeholder="your@email.com"
      />
      <div className="top-1 right-1 mt-5 block flex justify-center rounded border-none lg:absolute lg:mt-0 ">
        <span
          onClick={() => collectEmailForNewsletter()}
          className="main-button bg-primary text-white"
          role="button"
        >
          Get notified
        </span>
      </div>
    </div>
  );
}
