import React, { useState } from "react";

export default function SimpleSwitch({ onChange }) {
  const [isOn, setIsOn] = useState(false);

  return (
    <button
      className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={async function () {
        onChange(!isOn);
        await setIsOn(!isOn);
      }}
    >
      <div className="h-8 w-16 rounded-full bg-indigo-500 shadow-md outline-none transition"></div>
      <div
        className={
          "absolute top-1 left-1 inline-flex h-6 w-6 transform items-center justify-center rounded-full bg-white shadow-sm transition-all duration-200 ease-in-out " +
          (isOn ? "translate-x-8" : "translate-x-0")
        }
      ></div>
    </button>
  );
};
