/*global chrome*/
import {replyBuddyChromeExtIdDev, replyBuddyChromeExtIdProd} from "../config";
import {createCustomToken} from "./apiRequests";

async function sendMessageToExtensions(extId, message) {
  try {
    chrome.runtime.sendMessage(extId, message, function (response) {
      if (!response || !response.success) {
        return response;
      }
    });
  } catch (e) {
    console.log(e);
  }
}

export async function sendCustomToExtensions() {
  const customToken = await createCustomToken();
  await sendMessageToExtensions(replyBuddyChromeExtIdProd, {
    command: "customToken",
    data: customToken,
  });
  await sendMessageToExtensions(replyBuddyChromeExtIdDev, {
    command: "customToken",
    data: customToken,
  });
}
