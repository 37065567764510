import React from "react";
import SimpleModal from "../common/SimpleModal";
import TemplatesEditor from "./TemplatesEditor";

export default function TemplateMessageModal({
  showModal,
  setShowModal,
  onTemplatesSaved,
}) {
  return (
    <SimpleModal showModal={showModal} setShowModal={setShowModal}>
      <h1 className="m-3 text-center text-[1.37rem] font-bold">Templates</h1>
      <TemplatesEditor
        onTemplatesSaved={(templateMessages) => {
          onTemplatesSaved(templateMessages);
          setShowModal(false);
        }}
      />
      <div className="m-3 text-end">
        <button
          className="main-button mx-2 inline-block border"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
      </div>
    </SimpleModal>
  );
}
