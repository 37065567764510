import React, { useEffect, useState } from "react";
import ReviewUserInfo from "./ReviewUserInfo";
import ReviewStars from "./ReviewStars";
import MessageSentiment from "./MessageSentiment";
import ReviewText from "./ReviewText";
import ReviewReply from "./ReviewReply";
import ReplyReviewInput from "./ReplyReviewInput";
import ReplyReviewButton from "./ReplyReviewButton";
import GenerateButton from "./GenerateButton";
import TemplatesButton from "./TemplatesButton";
import { useDispatch, useSelector } from "react-redux";
import { consumeToken } from "../../utils/subscriptionSlice";
import { isPaidUser } from "../../utils/firebaseUtil";
import SimilarScore from "./SimilarScore";

export default function ReviewElement({
  conversation,
  templateMessages,
  isUsingPlatformApi,
  showTemplateModal,
}) {
  const [developerReplyText, setDeveloperReplyText] = useState("");
  const [developerReplyDate, setDeveloperReplyDate] = useState("");
  const [textToReply, setTextToReply] = useState("");
  const [sentiment, setSentiment] = useState("");
  const [similarMemories, setSimilarMemories] = useState(null);
  const [generatedCount, setGeneratedCount] = useState(0);
  const subscriptionType = useSelector((state) => state.subscription.type);
  const dispatch = useDispatch();

  useEffect(() => {
    setReviewElement(conversation).then();
  }, [conversation]);

  async function setReviewElement(convJson) {
    let inMessage = convJson["last_incoming_message"];
    let outMessage = convJson["last_outgoing_message"];
    if (developerReplyText || outMessage) {
      await setDeveloperReplyText(developerReplyText || outMessage["text_translated"]);
      await setDeveloperReplyDate(developerReplyDate || outMessage["text_modified_on"]);
    } else {
      await setGeneratedReply(textToReply, inMessage["generated_replies"]);
    }
    await setSentiment(sentiment || inMessage["sentiment"]);
    if (inMessage['last_generated_reply']) {
      await setSimilarMemories(similarMemories || inMessage['last_generated_reply']["similar_memories"]);
    }
    await setGeneratedCount(generatedCount || inMessage["generated_replies"].length);
  }

  async function setGeneratedReply(defaultValue, generatedReplies) {
    if (generatedReplies && generatedReplies.length > 0) {
      let lastGeneratedReply = generatedReplies[generatedReplies.length - 1];
      await setTextToReply(defaultValue || lastGeneratedReply["generated_reply_text"]);
    }
  }

  async function handleGeneratedReplyResponse(response) {
    await setReviewElement(response);
    await setGeneratedReply(null, response["last_incoming_message"]["generated_replies"]);
    dispatch(consumeToken());
  }

  async function handleReplySent() {
    await setDeveloperReplyText(textToReply);
    await setDeveloperReplyDate(new Date());
    await setTextToReply("");
  }

  function isGeneratedTextOverflown() {
    return (textToReply || "").length > 350;
  }

  return (
    <>
      <div className="mb-2">
        <span className="mr-2">
          <ReviewUserInfo
            authorName={conversation["last_incoming_message"]["author_name"]}
          />
        </span>
        <ReviewStars rating={conversation["rating"]} />
        <MessageSentiment sentiment={sentiment} />
        <SimilarScore similarMemories={similarMemories} />
      </div>
      <div className="mr-[5%] mb-2">
        <ReviewText
          text={conversation["last_incoming_message"]["text"]}
          textTranslated={
            conversation["last_incoming_message"]["text_translated"]
          }
          languageCode={
            conversation["last_incoming_message"]["text_language_code"]
          }
          modifiedOn={conversation["last_incoming_message"]["text_modified_on"]}
        />
      </div>
      <div className="ml-[5%] mb-2">
        <ReviewReply
          text={developerReplyText}
          modifiedOn={developerReplyDate}
        />
      </div>
      {isUsingPlatformApi && (
        <div>
          <div className="flex">
            <ReplyReviewInput
              textToReply={textToReply}
              onReplyChanged={(val) => setTextToReply(val)}
              isGeneratedTextOverflown={isGeneratedTextOverflown}
            />
            <ReplyReviewButton
              conversationId={conversation["id"]}
              replyText={textToReply}
              onReplySent={handleReplySent}
              isGeneratedTextOverflown={isGeneratedTextOverflown}
            />
          </div>
          <center className="mx-auto mt-3 block">
            {isPaidUser(subscriptionType) && (
              <GenerateButton
                appId={conversation["app_id"]}
                conversationId={conversation["id"]}
                generatedCount={generatedCount}
                handleGeneratedReplyResponse={handleGeneratedReplyResponse}
              />
            )}
            <TemplatesButton
              templates={templateMessages}
              onTemplateClick={(template) => setTextToReply(template)}
              onShowTemplateModal={() => showTemplateModal()}
            />
          </center>
        </div>
      )}
    </>
  );
}
