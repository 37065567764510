import React, { useEffect, useState } from "react";
import { deleteOpenAiFile, listOpenAiFiles } from "../../utils/apiRequests";

export default function OpenAiFiles() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    loadFiles();
  }, []);

  function loadFiles() {
    listOpenAiFiles().then(setFiles);
  }

  return (
    <>
      <h1 className="my-10 text-[2rem]">OpenAI Files</h1>
      <div>
        {files
          .sort((a, b) => {
            return new Date(b["created_at"]) - new Date(a["created_at"]);
          })
          .map((file) => (
            <div key={file.id} className="flex">
              <div className="mx-20 my-4 flex-grow border py-1 px-10">
                <h3>{file.filename}</h3>
                <p>{file.purpose}</p>
              </div>
              <button
                id="input-group-dropdown-1"
                className="my-auto mx-4 h-auto rounded border border-red-300 p-2 text-red-600"
                onClick={async () => {
                  await deleteOpenAiFile(file.id);
                  loadFiles();
                }}
              >
                Delete
              </button>
            </div>
          ))}
      </div>
    </>
  );
}
