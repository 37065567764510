import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" md:px-footer center  bg-primary25 py-5 px-4 font-lato font-normal text-neutral ">
        <div className="container m-auto flex w-full max-w-[1089px]  flex-row items-center justify-between">
          <h5 className="hidden text-[14px] sm:block">ReplyBuddy AI 2022 </h5>
          <h5 className="block text-[12px] sm:hidden small:text-[14px]">
            © 2022
          </h5>
          <div className="">
            <h5
              role="button"
              className="inline px-1 text-[12px]  sm:px-6 small:px-2 small:text-[14px]"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </h5>
            <h5
              role="button"
              className="inline px-1 text-[12px] sm:px-6 small:px-2 small:text-[14px]"
              onClick={() => navigate("/terms-of-use")}
            >
              Terms of use
            </h5>
            <h5
              role="button"
              className="inline pl-1 text-[12px] sm:pl-6 small:pl-2 small:text-[14px]"
              onClick={() => navigate("/contact")}
            >
              Contact us
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
