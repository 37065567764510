import { deleteApp } from "../../utils/apiRequests";
import React from "react";
import { useParams } from "react-router-dom";

export default function AppSettingsEditor({ onAppSettingsSaved }) {
  let { appId } = useParams();

  return (
    <center>
      <button
        className="main-button text-red-600 mx-5 inline-block rounded-xl border px-4 py-1"
        onClick={async () => {
          await deleteApp(appId);
          onAppSettingsSaved();
        }}
      >
        Delete App
      </button>
    </center>
  );
}
