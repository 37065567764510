import React, { useEffect, useState } from "react";
import {
  generateReply,
  getApp,
  getTemplateMessages,
  listConversations,
} from "../utils/apiRequests";
import { useNavigate, useParams } from "react-router-dom";
import MessageFilters from "../components/review/MessageFilters";
import ReviewElement from "../components/review/ReviewElement";
import CustomPagination from "../components/common/CustomPagination";
import TemplateMessageModal from "../components/apps/TemplateMessageModal";
import { playConsoleApi } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import SimpleSpinner from "../components/common/SimpleSpinner";

export default function Messages() {
  const [app, setApp] = useState(null);
  const [isLoadingReviews, setIsLoadingMessages] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingIndex, setGeneratingIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterList, setFilterList] = useState([]);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  let { appId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTemplateMessages(appId).then(setTemplateMessages);
    getApp(appId).then(setApp);
  }, [appId]);

  useEffect(() => {
    initMessages().then();
  }, [filterList, page]);

  useEffect(() => {}, [conversations]);

  async function initMessages() {
    await setIsLoadingMessages(true);
    let paginatedConversations = await listConversations(appId, filterList, page);
    setPageCount(paginatedConversations["pages"]);
    setPerPage(paginatedConversations["per_page"]);
    setTotal(paginatedConversations["total"]);
    await extendConversations([], paginatedConversations["conversations"]);
    await setIsLoadingMessages(false);
  }

  async function extendConversations(currentConversations, conversationsResponse) {
    let newConversations = currentConversations.concat(conversationsResponse);
    await setConversations(newConversations);
  }

  async function onGenerateAll() {
    await setIsGenerating(true);
    for (let i = 0; i < conversations.length; i++) {
      setGeneratingIndex(i);
      let generatedReplies = conversations[i]['last_incoming_message']['generated_replies'];
      if (generatedReplies.length === 0) {
        let newConversations = [...conversations];
        newConversations[i] = await generateReply(appId, conversations[i]["id"]);
        await setConversations(newConversations);
      }
    }
    await setIsGenerating(false);
  }

  return (
    <>
      <center className="h3 p-3">
        {app && app["platform"] === playConsoleApi && (
          <input
            type="button"
            className="main-button mx-2 inline-block border"
            disabled={isGenerating}
            onClick={() => onGenerateAll().then((_) => true)}
            value={
              isGenerating
                ? `Generating ${generatingIndex + 1}/${conversations.length}`
                : `Generate ${perPage}`
            }
          />
        )}
        <span> {app && (app["name"] || app["package_name"])} </span>
        <FontAwesomeIcon
          icon={faGear}
          className="px-1"
          role="button"
          onClick={() => navigate(`/apps/${appId}/app-settings`)}
        />
      </center>
      <div className="m-auto flex w-[80%] justify-end">
        <div className="my-auto mr-5">
          <span>Total: {total}</span>
        </div>
        <MessageFilters filterList={filterList} setFilterList={setFilterList} />
      </div>
      {isLoadingReviews && (
        <center>
          <SimpleSpinner />
        </center>
      )}
      {!isLoadingReviews &&
        conversations.map((conversation) => {
          return (
            <div key={conversation["id"]} className="m-auto w-[80%]">
              <div className="p-7">
                <ReviewElement
                  conversation={conversation}
                  templateMessages={templateMessages}
                  isUsingPlatformApi={app && app["platform"] === playConsoleApi}
                  showTemplateModal={() => setShowTemplates(true)}
                />
              </div>
              <hr className="border-1 border-dark border" />
            </div>
          );
        })}

      {!isLoadingReviews && pageCount > 1 && (
        <div className="py-3">
          <CustomPagination
            pageCount={pageCount}
            page={page}
            setPage={setPage}
          />
        </div>
      )}

      <TemplateMessageModal
        showModal={showTemplates}
        setShowModal={(val) => setShowTemplates(val)}
        onTemplatesSaved={(newTemplateMessages) =>
          setTemplateMessages(newTemplateMessages)
        }
      />
    </>
  );
}
