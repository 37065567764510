import SimpleModal from "../common/SimpleModal";

export default function OutOfTokensModal({ showModal, setShowModal }) {
  return (
    <SimpleModal showModal={showModal} setShowModal={setShowModal}>
      <div className="mx-10 my-5">
        <h2 className="text-[2rem]">Out Of Tokens</h2>
        <p className="my-5">
          Please contact us at <b>developers@kallossoft.com</b> to get more
          tokens
        </p>
        <div className="flex justify-end">
          <button className="main-button" onClick={() => setShowModal(false)}>
            Close
          </button>
        </div>
      </div>
    </SimpleModal>
  );
};
