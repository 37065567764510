import React from "react";
import mailbox from "../../images/mailbox.png";

export default function VerifyEmailText() {
  return (
    <div className="offset-md-0 offset-lg-2 offset-xl-4 mt-6">
      <div>
        <center>
          <img alt="mailbox" width="100em" src={mailbox} />
        </center>
      </div>
      <div className="text-center">
        <p className="ml-md mt-4">
          We have sent an email with a confirmation link to your email address.{" "}
          <br />
          Please check your spam folder if you don't see it in 5-10 minutes.
        </p>
      </div>
    </div>
  );
}
