export default function ProgressBar({ percent }) {
  return (
    <div className="mb-6 h-5 w-full rounded bg-gray-200 shadow">
      <div
        className="h-5 rounded bg-blue-600"
        style={{ width: percent + "%" }}
      ></div>
    </div>
  );
}
