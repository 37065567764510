import React from "react";
import "../../index.css";

export default function Works() {
  return (
    <>
      <div className="container mx-auto my-10 max-w-[871px] px-6 query:my-20 query:px-0">
        <h1 className="text-center font-latosemibold text-[2rem] text-neutral900 md:text-[2.25rem] query:text-left">
          How it works
        </h1>

        <div className="mt-16 query:mt-12">
          <div className="img relative ">
            <img
              src={require("../../images/bg-snaps.png")}
              alt="item2"
              className="hidden w-[91%] query:ml-[1.7rem] query:block"
            />
            <div className="-query:left-2 flex  flex-row query:absolute query:top-0">
              <img
                src={require("../../images/work-1.png")}
                alt="one"
                className="h-16 w-16"
              />
              <div className="flex flex-col pl-6">
                <h3 className="font-latosemibold text-[1.37rem] leading-[1.65rem] text-purplish">
                  Chrome Extension
                </h3>
                <p className="pt-3 font-lato text-[18px] font-normal text-neutral">
                  Reply directly from your browser. Easy!
                </p>
              </div>
            </div>

            <img
              src={require("../../images/img-work-mob.png")}
              alt="arrow"
              className="my-6 mx-auto block query:hidden"
            />

            <div className="flex flex-row query:absolute query:top-32.5 query:left-[26.5rem]">
              <img
                src={require("../../images/work-2.png")}
                alt="one"
                className="h-16 w-16"
              />
              <div className="flex flex-col pl-6">
                <h3 className="font-latosemibold text-[1.37rem] leading-[1.65rem] text-purplish">
                  All-In-One Inbox
                </h3>
                <p className="pt-3 font-lato text-[18px] font-normal text-neutral">
                  Reviews, emails, messages... All in one place
                </p>
              </div>
            </div>

            <img
              src={require("../../images/img-work-mob.png")}
              alt="arrow"
              className="my-6 mx-auto block query:hidden"
            />

            <div className="-query:left-6 flex flex-row query:absolute query:top-68">
              <img
                src={require("../../images/work-3.png")}
                alt="one"
                className="h-16 w-16"
              />
              <div className="flex flex-col pl-6">
                <h3 className="font-latosemibold text-[1.37rem] leading-[1.65rem] text-purplish">
                  Personalized Replies
                </h3>
                <p className="pt-3 font-lato text-[18px] font-normal text-neutral">
                  Addressing customers pain points
                </p>
              </div>
            </div>

            <img
              src={require("../../images/img-work-mob.png")}
              alt="arrow"
              className="my-6 mx-auto block query:hidden"
            />
            <div className="flex flex-row query:absolute query:query:top-102 query:query:left-[27rem]">
              <img
                src={require("../../images/work-4.png")}
                alt="one"
                className="h-16 w-16"
              />
              <div className="flex flex-col pl-6">
                <h3 className="font-latosemibold text-[1.37rem] leading-[1.65rem] text-purplish">
                  Learns From You
                </h3>
                <p className="pt-3 font-lato text-[18px] font-normal text-neutral">
                  The more you use it, the better it gets
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
