import React, { useEffect, useState } from "react";
import {
  createFineTuningModel,
  listFineTuning,
} from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import OpenAiFineTunedModels from "../components/finetuning/OpenAiFineTunedModels";
import OpenAiFiles from "../components/finetuning/OpenAiFiles";
import FineTuningElement from "../components/finetuning/FineTuningElement";

export default function FineTuning() {
  const [fineTunings, setFineTunings] = useState([]);
  const [newModelName, setNewModelName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchModels();
  }, []);

  function fetchModels() {
    listFineTuning().then(setFineTunings);
  }

  return (
    <center className="mx-auto mt-10 max-w-[82rem]">
      <h1 className="my-10 font-latosemibold text-[2rem]">Local Fine-Tuning</h1>
      {fineTunings.map((fineTuning) => (
        <FineTuningElement
          key={fineTuning.id}
          modelId={fineTuning.id}
          modelName={fineTuning.name}
          fetchModels={fetchModels}
        />
      ))}

      <div className="my-5 w-full flex-col items-center justify-center rounded-lg bg-white p-5">
        <input
          className="my-5 inline-block rounded-lg border-2 border-gray-300 p-2 focus:border-blue-500 focus:outline-none"
          type="text"
          placeholder="Model Name"
          value={newModelName}
          onChange={(e) => setNewModelName(e.target.value)}
        />
        <button
          className="main-button mx-5 inline-block rounded-xl border px-4 py-1"
          onClick={async () => {
            await createFineTuningModel(newModelName);
            await fetchModels();
          }}
        >
          Create new model
        </button>
      </div>

      <OpenAiFiles />
      <OpenAiFineTunedModels />
    </center>
  );
}
