import mailbox from "../images/mailbox.png";
import meet from "../images/google_meet.png";
import React from "react";

export default function Contact() {
  return (
    <center>
      <div className="mt-3">
        <p className="fw-bold fs-1-8">Send us a message</p>
        <p>
          Send an e-mail to <b>kallossoft@gmail.com</b>
          <br />
          and we will reply within 24 hours
        </p>
        <img alt="mailbox" className="mx-auto mb-3 w-20" src={mailbox} />
      </div>
      <div className="mt-3 text-center">
        <p className="fw-bold fs-1-8">or</p>
      </div>
      <div className="mt-3 text-center">
        <p className="fw-bold fs-1-8">Arrange a call</p>
        <p>
          If you have a technical question about platform, <br /> book a
          15-minute call with our support
          <a
            href={`https://calendly.com/mila-kallos3/customer-support`}
            target="_blank"
            className="mx-2 rounded bg-primary px-2 py-1 text-white"
            rel="noreferrer"
          >
            here
          </a>
        </p>
        <img alt="mailbox" className="mx-auto mb-3 w-20" src={meet} />
      </div>
    </center>
  );
}
