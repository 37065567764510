import React from "react";

export default function CustomTabs({ tabNames, onTabChanged }) {
  const [activeTab, setActiveTab] = React.useState(tabNames[0]);

  return (
    <ul className="my-5 w-fit border-b border-gray-200 px-10">
      {tabNames.map((tabName) => (
        <li key={tabName} className="mr-2 inline-block">
          <span
            className={
              "text-sm inline-block cursor-pointer rounded-t-lg py-4 px-4 text-center font-medium text-gray-500 " +
              (activeTab === tabName ? "bg-gray-100 text-primary" : "")
            }
            onClick={() => {
              setActiveTab(tabName);
              onTabChanged(tabName);
            }}
          >
            {tabName}
          </span>
        </li>
      ))}
    </ul>
  );
}
