import React, { useState } from "react";
import CustomTabs from "../components/common/CustomTabs";
import TemplatesEditor from "../components/apps/TemplatesEditor";
import AppSettingsEditor from "../components/apps/AppSettingsEditor";
import { useNavigate } from "react-router-dom";
import MemoryEditor from "../components/apps/MemoryEditor";

export default function AppSettings() {
  const memoryTab = "Memory Tab";
  const templateMessagesTab = "Template Messages Tab";
  const appSettingsTab = "App Settings Tab";
  const [activeTab, setActiveTab] = useState(memoryTab);
  const navigate = useNavigate();

  return (
    <>
      <center>
        <CustomTabs
          tabNames={[memoryTab, templateMessagesTab, appSettingsTab]}
          onTabChanged={setActiveTab}
        />
      </center>
      {activeTab === memoryTab && <MemoryEditor onMemoriesSaved={() => {}} />}
      {activeTab === templateMessagesTab && (
        <TemplatesEditor onTemplatesSaved={() => {}} />
      )}
      {activeTab === appSettingsTab && (
        <AppSettingsEditor
          onAppSettingsSaved={() => {
            navigate("/apps");
          }}
        />
      )}
    </>
  );
}
