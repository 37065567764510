import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function ReviewStars({ rating }) {
  return (
    <>
      {[...Array(rating).keys()].map((star) => (
        <FontAwesomeIcon key={star} icon={faStar} className="text-orange-300" />
      ))}
      <span className="mx-2">{rating}</span>
    </>
  );
}
