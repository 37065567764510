import React from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../common/ProgressBar";

export default function AppThumbnail({ app }) {
  const navigate = useNavigate();
  const percentValue = parseInt(
    (app["conversation_replied_count"] / app["conversation_count"]) * 100
  );
  return (
    <div
      className="m-3 border-[1px] border-solid p-2 shadow"
      onClick={() => navigate(`/apps/${app["id"]}/messages`)}
      role="button"
    >
      <div className="mt-3">
        <div className="flex">
          <img
            className="w-1/5"
            alt="reply example"
            src={require("../../images/play-store-logo.png")}
          />
          <span className="w-4/5 text-center font-bold">
            {app["name"] || app["package_name"]}{" "}
          </span>
        </div>
        <div className="mt-7 text-center">
          Replied last 7 days: {app["conversation_replied_count"]} /{" "}
          {app["conversation_count"]}
        </div>
        <div className="p-4">
          <ProgressBar percent={percentValue} />
        </div>
      </div>
    </div>
  );
}
