import React, { useEffect, useState } from "react";
import {
  addMemory,
  deleteMemory,
  listMemories,
  updateMemory,
} from "../../utils/apiRequests";
import { useParams } from "react-router-dom";
import MemoryElement from "./MemoryElement";

export default function MemoryEditor({ onMemoriesSaved }) {
  const [memories, setMemories] = useState([]);
  let { appId } = useParams();

  useEffect(() => {
    loadMemories();
  }, [appId]);

  function loadMemories() {
    listMemories(appId).then(setMemories);
  }

  return (
    <div className="w-[100%]">
      <center>
        <button
          className="main-button mx-5 inline-block rounded-xl border px-4 py-1"
          onClick={async () => {
            let newMemory = {
              rating: 5,
              incoming_text: "",
              outgoing_text: "",
            };
            await addMemory(appId, newMemory);
            loadMemories();
          }}
        >
          +
        </button>
      </center>
      <br />

      {memories.map((memory, index) => (
        <MemoryElement
          key={memory.id}
          memory={memory}
          onMemoryChanged={(key, value) => {
            let newMemories = [...memories];
            newMemories[index][key] = value;
            setMemories(newMemories);
          }}
          onUserStoppedTyping={async () => {
            await updateMemory(appId, memory);
            onMemoriesSaved();
          }}
          onDeleteMemory={async () => {
            await deleteMemory(appId, memory.id);
            loadMemories();
          }}
        />
      ))}
    </div>
  );
}
