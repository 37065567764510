import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isPaidUser } from "../../utils/firebaseUtil";

export default function AddAppButtons() {
  const subscriptionType = useSelector((state) => state.subscription.type);
  const navigate = useNavigate();

  return (
    <div className="m-auto">
      {isPaidUser(subscriptionType) && (
        <input
          type="button"
          role="button"
          className="main-button mx-5 inline-block bg-primary text-white"
          onClick={() => navigate("/apps/add")}
          value="Add App"
        />
      )}
      {subscriptionType === "admin" && (
        <input
          type="button"
          role="button"
          className="main-button inline-block bg-primary text-white"
          onClick={() => navigate("/apps/add-test")}
          value="Add Test App"
        />
      )}
    </div>
  );
};
