import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

export default function MessageFilter({ filterList, setFilterList }) {
  const filterNames = [
    "Unanswered",
    "Answered",
    "-",
    "5-Star",
    "4-Star",
    "3-Star",
    "2-Star",
    "1-Star",
    "-",
    "Positive",
    "Negative",
  ];

  return (
    <FormControl className="w-[30%] max-w-xl" size="small">
      <InputLabel>Filters</InputLabel>
      <Select
        multiple
        value={filterList}
        onChange={(e) => setFilterList(e.target.value)}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {filterNames.map((filterItem, index) => {
          if (filterItem === "-") {
            return <div key={index} className="border-1 border" />;
          } else {
            return (
              <MenuItem key={index} value={filterItem}>
                <Checkbox checked={filterList.indexOf(filterItem) > -1} />
                <ListItemText primary={filterItem} />
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};
