import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  deleteTrainingData,
  getTrainingData,
  insertTrainingData,
  updateTrainingData,
} from "../utils/apiRequests";
import TrainingDataElement from "../components/finetuning/TrainingDataElement";

export default function TrainingData() {
  const [trainingDataList, setTrainingDataList] = useState([]);
  let { fineTuningId } = useParams();

  useEffect(() => {
    loadTrainingData();
  }, [fineTuningId]);

  function loadTrainingData() {
    getTrainingData(fineTuningId).then(setTrainingDataList);
  }

  function setTrainingData(index, key, value) {
    const newTrainingDataList = [...trainingDataList];
    newTrainingDataList[index][key] = value;
    setTrainingDataList(newTrainingDataList);
  }

  return (
    <center className="m-10">
      {trainingDataList.map((trainingData, index) => (
        <div key={index} className="my-10 flex">
          <div className="inline-block flex-grow">
            <TrainingDataElement
              before={trainingData.before}
              onBeforeChange={(value) => {
                setTrainingData(index, "before", value);
              }}
              after={trainingData.after}
              onAfterChange={(value) => {
                setTrainingData(index, "after", value);
              }}
              onUserStoppedTyping={async () => {
                await updateTrainingData(trainingData);
              }}
            />
          </div>
          <button
            id="input-group-dropdown-1"
            className="my-auto mx-4 h-auto rounded border border-red-300 p-2 text-red-600"
            onClick={async () => {
              await deleteTrainingData(trainingData["id"]);
              loadTrainingData();
            }}
          >
            Delete
          </button>
        </div>
      ))}
      <div>
        <button
          className="main-button mx-5 inline-block rounded-xl border px-4 py-1"
          onClick={async () => {
            let insertedTrainingData = await insertTrainingData(fineTuningId, "", "");
            let newTrainingDataList = [...trainingDataList];
            newTrainingDataList.push(insertedTrainingData);
            setTrainingDataList(newTrainingDataList);
          }}
        >
          +
        </button>
      </div>
    </center>
  );
}
