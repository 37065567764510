import { createSlice } from "@reduxjs/toolkit";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    type: "free",
    tokens: null,
  },
  reducers: {
    setSubscriptionType: (state, action) => {
      state.type = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
    consumeToken: (state) => {
      state.tokens -= 1;
    },
  },
});

export const { setSubscriptionType, setTokens, consumeToken } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
