import React, { useEffect, useState } from "react";
import {
  deleteOpenAiFineTunedModel,
  listOpenAiFineTunedModels,
} from "../../utils/apiRequests";

export default function OpenAiFineTunedModels() {
  const [models, setModels] = useState([]);

  useEffect(() => {
    listFineTuningModels();
  }, []);

  function listFineTuningModels() {
    listOpenAiFineTunedModels().then(setModels);
  }

  let systemOwners = ["openai", "openai-dev", "system"];
  return (
    <>
      <h1 className="my-10 text-[2rem]">OpenAI Models</h1>
      <div>
        {models
          .sort((a, b) => {
            return new Date(b["created"]) - new Date(a["created"]);
          })
          .map((model) => {
            return (
              <div key={model["id"]} className="flex">
                <div className="mx-20 my-4 flex-grow border py-1 px-10">
                  <h3>{model["id"]}</h3>
                  <p>{new Date(model["created"] * 1000).toLocaleString()}</p>
                </div>
                {!systemOwners.includes(model["owned_by"]) && (
                  <button
                    id="input-group-dropdown-1"
                    className="my-auto mx-4 h-auto rounded border border-red-300 p-2 text-red-600"
                    onClick={async () => {
                      await deleteOpenAiFineTunedModel(model["id"]);
                      listFineTuningModels();
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
