import React, { useEffect, useState } from "react";
import { auth, isLoggedIn, isPaidUser } from "../utils/firebaseUtil";
import { listApps } from "../utils/apiRequests";
import AppThumbnail from "../components/dashboard/AppThumbnail";
import VerifyEmailText from "../components/dashboard/VerifyEmailText";
import { useSelector } from "react-redux";
import AddAppButtons from "../components/dashboard/AddAppButtons";
import { CircularProgress } from "@mui/material";
import Welcome from "../components/dashboard/Welcome";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const subscriptionType = useSelector((state) => state.subscription.type);

  useEffect(() => {
    listApps().then(async (result) => {
      await setApps(result);
      await setIsLoading(false);
    });
  }, []);
  useEffect(() => {}, [isLoading]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isLoggedIn()) {
    return <div>Login to continue!</div>;
  }

  if (auth.currentUser.emailVerified || isPaidUser(subscriptionType)) {
    if (apps.length) {
      return (
        <div className="grid grid-cols-3 gap-4">
          {apps.map((app) => (
            <AppThumbnail key={app.id} app={app} />
          ))}
          <AddAppButtons />
        </div>
      );
    } else {
      return <Welcome />;
    }
  } else {
    return <VerifyEmailText />;
  }
}
