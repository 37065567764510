import React, { useState } from "react";
import { auth } from "../utils/firebaseUtil";
import {
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { syncUser } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";

export default function MyProfile() {
  const [nameChanged, setNameChanged] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  async function changeName(e) {
    setName(e.target.value);
    setNameChanged(false);
  }

  async function changeNameAfterSaving() {
    await updateProfile(auth.currentUser, { displayName: name });
    setNameChanged(true);
  }

  async function sendVerificationLink() {
    await sendEmailVerification(auth.currentUser);
    alert("Your verification link was sent to your email");
  }

  const sendResetLink = async () => {
    await genericLogin(
      () => sendPasswordResetEmail(auth, auth.currentUser.email),
      false
    );
    alert("Your password reset link was sent to your email");
  };

  async function genericLogin(loginFunction, navigateToApps = false) {
    try {
      await loginFunction();
      await syncUser();
      if (navigateToApps && auth.currentUser) {
        navigate("/apps");
      }
    } catch (err) {
      alert(err.message);
    }
  }

  return (
    <>
      <h1 className="mb-10 text-center text-[2rem] font-bold">My profile</h1>
      <div className="mx-auto grid w-80 grid-cols-2 gap-y-5">
        <label className="font-bold">Name</label>
        <input
          type="name"
          className="mt-[-6px] rounded-lg border border-gray-400 px-2 py-1"
          defaultValue={auth.currentUser.displayName}
          onChange={(e) => changeName(e)}
        />
        <label className="font-bold">Email</label>
        <div>
          <input
            type="name"
            defaultValue={auth.currentUser.email}
            disabled={true}
            onChange={(e) => setName(e.target.value)}
          />
          {!auth.currentUser.emailVerified && (
            <span role="link" onClick={sendVerificationLink}>
              Verify
            </span>
          )}
        </div>
        <span className="font-bold">Password</span>
        <button
          className="main-button mt-[-6px] rounded-lg border border-gray-500 bg-primary px-2 py-1 text-white"
          onClick={sendResetLink}
        >
          Reset my password
        </button>
      </div>
      <button
        className="main-button my-10 mx-auto bg-primary px-4 py-1 text-white disabled:cursor-not-allowed disabled:opacity-50"
        onClick={changeNameAfterSaving}
        disabled={nameChanged || !name}
      >
        Save
      </button>
    </>
  );
}
