import { styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#faf8ff",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const QuestionAccordion = ({ title, text }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography
        sx={{ fontSize: "20px", color: "#070538", fontFamily: "Lato-Semibold" }}
      >
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography
        sx={{ fontSize: "16px", color: "#8A96A6", fontFamily: "Lato" }}
      >
        {text}
      </Typography>
    </AccordionDetails>
  </Accordion>
);

export default function FAQ() {
  return (
    <div className="mx-auto mt-[7rem] max-w-[55rem]">
      <h1 className="mb-[2rem] font-latosemibold text-[2rem] text-neutral900 md:text-[2.25rem]">
        Frequently Asked Questions
      </h1>
      <div className="">
        <QuestionAccordion
          title="Is it replying automatically"
          text="No, you will need to click the reply button to reply to a comment."
        />
        <QuestionAccordion
          title="How do I reply to a comment?"
          text="Click the reply button to reply to a comment."
        />
        <QuestionAccordion
          title="Is it free to reply to a comment?"
          text="Yes, it is free to reply to a comment."
        />
      </div>
    </div>
  );
}
