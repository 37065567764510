import React, { useState } from "react";
import { addApp, uploadReviewFile } from "../utils/apiRequests";

export default function AddTestApp() {
  const [isLoading, setIsLoading] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [reviewFile, setReviewFile] = useState(null);

  async function onAddApp() {
    setIsLoading(true);
    let appResponse = await addApp(packageName, "test@test.com", packageName);
    if (appResponse["id"]) {
      await uploadReviewFile(appResponse["id"], reviewFile);
    }
    setIsLoading(false);
  }

  return (
    <>
      <center className="fw-bold fs-1-8 my-8">
        <span>Add Test App</span>
      </center>
      <div className="mx-auto grid w-[40rem] grid-cols-3 gap-y-4 gap-x-2">
        <label>Package Name</label>
        <input
          type="text"
          className="col-span-2 w-full rounded-md border border-gray-300 px-2 py-1"
          value={packageName}
          onChange={(e) => setPackageName(e.target.value)}
        />
        <label>Default file input example</label>
        <input
          type="file"
          className="col-span-2"
          onChange={(e) => setReviewFile(e.target.files[0])}
        />
      </div>

      <input
        type="button"
        className="mx-auto mt-8 block rounded border border-gray-500 bg-primary px-4 py-2 text-white"
        disabled={isLoading}
        onClick={!isLoading ? () => onAddApp() : null}
        value={isLoading ? "Saving..." : "Save"}
      />
    </>
  );
}
