import React from "react";
import "../../index.css";
import NewsletterEmail from "./NewsletterEmail";

export default function Newsletter() {
  return (
    <div className=" container mx-auto w-[90%] max-w-[1089px] py-20 md:px-0 ">
      <div className="newsletter w-full items-center rounded-2xl bg-primary bg-[url('images/newsletter-bg.png')] bg-cover  bg-no-repeat px-4  pt-2.37 pb-2.37 query:h-[9.875rem] query:bg-contain query:bg-rr query:px-11 query:pt-15 query:pb-bottom container-full:bg-right mdscreen:px-leftright">
        <div className="content flex flex-col justify-between query:flex-row query:items-center">
          <h3 className="mb-5 flex justify-center font-latosemibold text-[15px] text-white lg:text-[20px] query:mb-0 query:mt-0">
            Do you want to be noticed about updates?
          </h3>
          <NewsletterEmail />
        </div>
      </div>
    </div>
  );
}
