import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export function isLoggedIn() {
  return !!auth.currentUser;
}

export function isPaidUser(subscriptionType) {
  return ["trial", "pro", "admin"].includes(subscriptionType);
}

export function isAdmin(subscriptionType) {
  return subscriptionType === "admin";
}
