import SimpleSpinner from "../common/SimpleSpinner";
import React, { useState } from "react";
import { generateReply } from "../../utils/apiRequests";
import { useSelector } from "react-redux";

export default function GenerateButton({
  appId,
  conversationId,
  generatedCount,
  handleGeneratedReplyResponse,
}) {
  const [isGenerating, setGenerating] = useState(false);
  const tokens = useSelector((state) => state.subscription.tokens);

  async function onGenerateReply() {
    await setGenerating(true);
    let response = await generateReply(appId, conversationId);
    await setGenerating(false);
    handleGeneratedReplyResponse(response);
  }

  return (
    <button
      className="main-button mx-2 inline-block border text-center disabled:cursor-not-allowed disabled:opacity-50"
      onClick={() => onGenerateReply()}
      disabled={generatedCount > 3 || isGenerating || !tokens}
    >
      {isGenerating && (
        <div className="mr-3 inline-block">
          <SimpleSpinner />
        </div>
      )}
      Generate Reply
    </button>
  );
}
