import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../utils/firebaseUtil";
import { useNavigate } from "react-router-dom";
import google from "../images/google.png";
import micro from "../images/micro.png";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const providerMicrosoft = new OAuthProvider("microsoft.com");

  const signupWithEmailAndPassword = async (email, password, name, surname) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, {
        displayName: name + " " + surname,
      });
      await sendEmailVerification(auth.currentUser);
      navigate("/apps");
    } catch (err) {
      alert(err.message);
    }
  };

  const signupWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/apps");
    } catch (err) {
      alert(err.message);
    }
  };

  const signupWithMicrosoft = async () => {
    try {
      await signInWithPopup(auth, providerMicrosoft);
      await sendEmailVerification(auth.currentUser);
      navigate("/apps");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className="flex min-h-screen bg-primary25">
      <div className="mx-auto mt-16 flex max-w-sm flex-1 flex-col md:mt-32">
        <div className="w-full rounded bg-white px-6 py-8 text-black shadow-md">
          <h1 className="mb-5 text-center text-[2rem]">Sign up</h1>
          <button
            className="main-button mx-auto my-2 border border-primary"
            onClick={() => signupWithGoogle()}
          >
            <img className="mr-3 ml-1 w-5" src={google} alt="google logo" />
            <span className="text-[1.1rem]"> Sign up with Google</span>
          </button>
          <button
            className="main-button mx-auto my-2 border border-primary"
            onClick={() => signupWithMicrosoft()}
          >
            <img className="mr-3 ml-1 w-5" src={micro} alt="google logo" />
            <span className="text-[1.1rem]"> Sign up with Microsoft</span>
          </button>

          <hr className="my-5" />
          <div className="mb-4 grid grid-cols-2 gap-x-2 ">
            <input
              type="text"
              className="inline-block rounded border border-gray-200 p-3"
              name="firstname"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              className="inline-block rounded border border-gray-200 p-3"
              name="lastname"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <input
            type="text"
            className="mb-4 block w-full rounded border border-gray-200 p-3"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            className="mb-4 block w-full rounded border border-gray-200 p-3"
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            className="mb-4 block w-full rounded border border-gray-200 p-3"
            name="confirm_password"
            placeholder="Confirm Password"
          />

          <button
            type="submit"
            className="main-button mx-auto mt-5 bg-primary text-white"
            onClick={() =>
              signupWithEmailAndPassword(email, password, firstName, lastName)
            }
          >
            Create Account
          </button>

          <div className="mt-5 text-center text-[0.88rem] text-gray-500">
            By signing up, you agree to the
            <a
              className="text-grey-dark border-b border-gray-600 no-underline"
              href="/terms-of-use"
            >
              {" Terms of Service "}
            </a>
            and
            <a
              className="text-grey-dark border-b border-gray-600 no-underline"
              href="/privacy-policy"
            >
              {" Privacy Policy "}
            </a>
          </div>
        </div>
        <center className="mt-5">
          <span className="inline-block text-gray-600">
            Already have an account?
          </span>
          <input
            type="button"
            className="main-button mx-2 inline-block border border-primary"
            onClick={() => navigate("/login")}
            value="Login"
          />
        </center>
      </div>
    </div>
  );
}
