import React from "react";
import TextAreaDelayed from "../common/TextAreaDelayed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function MemoryElement({
  memory,
  onMemoryChanged,
  onUserStoppedTyping,
  onDeleteMemory,
}) {
  return (
    <div className="mb-5">
      <p className="ml-6 text-[12px] ">{memory["created_on"]}</p>
      <div className="flex">
        <div className="mx-5 flex-grow">
          <TextAreaDelayed
            className="w-full rounded border px-2 py-1"
            placeholder="Message Title"
            value={memory["incoming_text"] || ""}
            rows={2}
            onChange={(newValue) => onMemoryChanged("incoming_text", newValue)}
            onUserStoppedTyping={onUserStoppedTyping}
          />

          <TextAreaDelayed
            className="w-full rounded border px-2 py-1"
            placeholder="Message Title"
            value={memory["outgoing_text"] || ""}
            rows={3}
            onChange={(newValue) => onMemoryChanged("outgoing_text", newValue)}
            onUserStoppedTyping={onUserStoppedTyping}
          />
        </div>
        <div className="my-auto mr-5 inline-block">
          <FontAwesomeIcon
            icon={faTrash}
            className="main-button rounded-xl border p-2 text-red-600"
            onClick={onDeleteMemory}
          />
        </div>
      </div>
    </div>
  );
}
